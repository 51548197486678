/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/02/2021 12:20
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("../../../public/assets/fonts/Flaticon.eot");
    src: url("../../../public/assets/fonts/Flaticond41d.eot?#iefix")
            format("embedded-opentype"),
        url("../../../public/assets/fonts/Flaticon.woff2") format("woff2"),
        url("../../../public/assets/fonts/Flaticon.woff") format("woff"),
        url("../../../public/assets/fonts/Flaticon.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        /* src: url("../fonts/Flaticon.html#Flaticon") format("svg"); */
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-play-button:before {
    content: "\f100";
}
.flaticon-quotation:before {
    content: "\f101";
}
.flaticon-buildings:before {
    content: "\f102";
}
.flaticon-group:before {
    content: "\f103";
}
.flaticon-government:before {
    content: "\f104";
}
