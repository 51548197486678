@import url("./assets/css/animate.min.css");
@import url("./assets/css/remixicon.css");
@import url("./assets/css/flaticon.css");
@import url("./assets/css/magnific-popup.min.css");
@import url("./assets/css/odometer.min.css");
@import url("./assets/css/date-picker.min.css");
@import url("./assets/css/style.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/owl.theme.default.min.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/meanmenu.min.css");

:root {
    --primary-color: #027a48;
    --primary-color-light: #f6fef9;
}
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* CSS goes here. */

* {
    font-family: "Syne", sans-serif !important;
}

.hero-heading {
    font-size: 72px;
}
a {
    text-decoration: none;
    color: inherit;
}

.font-secondary {
    font-family: "Nanum Gothic", sans-serif !important;
}

.bg-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.text-theme {
    color: var(--primary-color) !important;
}

.bg-theme-light {
    background-color: var(--primary-color-light) !important;
}
.bg-theme {
    background-color: var(--primary-color) !important;
}

.font-20 {
    font-size: 20px !important;
}
.font-24 {
    font-size: 24px !important;
}
.font-30 {
    font-size: 27px !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-300 {
    font-weight: 300 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.rounded {
    border-radius: 9px !important;
}
.rounded-full {
    border-radius: 100px;
}
.text-gray {
    color: #344054bd !important;
}

.image_scroll_bar::-webkit-scrollbar {
    display: none;
}
.image_scroll_bar:hover::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: block;
}
.image_scroll_bar::-webkit-scrollbar-thumb {
    background-color: #00aa55;
    border-radius: 16px;
    box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
        inset -2px -2px 2px rgba(241, 137, 137, 0.25);
}
.image_scroll_bar::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}

input[type="text"]:focus {
    outline: none !important;
    border: none;
}

iframe {
    width: 100%;
    height: 80vh;
}

.text-justify {
    text-align: justify !important;
}

html {
    scroll-behavior: smooth;
}

.heroSection {
    position: relative;
    height: 92vh;
    overflow: hidden;
}

.slidesContainer {
    /* display: flex; */
    position: relative;
    transition: transform 1s ease-in-out;
    height: 100%;
}

.slide {
    min-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    left: 0%;
    top: 0;
    transition: opacity 1s ease-in-out;
    padding: 0 16px;
}

.slide.active {
    opacity: 1;
}

.textContainer {
    text-align: center;

    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
}

h1,
h3 {
    margin: 0;
    color: white !important;
    padding: 0.5rem 0;
}

.pagination {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 1rem;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    transition: background 0.3s;
}

.activeDot {
    background: green;
}
